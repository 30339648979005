.hero {
  position: relative;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Place video behind the content */
}

.content {
  position: relative;
  z-index: 10; /* Ensure content is above the video */
}
